<template>
  <b-container v-can="'provider.view'">
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Listado de Provedores</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              variant="primary"
              :to="{ name: 'provedores.register' }"
              v-can="'provider.create'"
              >Registrar Nuevo</b-button
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="6" class="my-3">
                <b-form-group>
                  <b-input-group>
                    <b-form-input
                      id="filter-input"
                      :disabled="
                        !Array.isArray(getProviders) ||
                        (getProviders.length === 0 && isBusy === false)
                      "
                      v-model="filter"
                      type="search"
                      placeholder="Buscar proveedores"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="12" class="table-responsive">
                <b-table
                  hover
                  :items="getProviders"
                  :fields="columns"
                  :filter="filter"
                  :busy="
                    !Array.isArray(getProviders) || getProviders.length === 0
                      ? true
                      : false || isBusy == true
                  "
                  :per-page="perPage"
                  :current-page="currentPage"
                  @filtered="onFiltered"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="isBusy">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong> Cargando datos...</strong>
                      </div>
                      <span class="h5" v-else>Sin registros</span>
                    </div>
                  </template>
                  <template v-slot:cell(comuna)="data">
                    <span>{{ data.item.comuna.name }}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <div v-if="!data.item.editable" class="row">
                      <b-button
                        v-b-tooltip.hover
                        title="Editar"
                        variant=" iq-bg-warning mr-1 mb-1 col-5"
                        size="sm"
                        :to="{ name: 'provedores.edit', params:{id: data.item.id}}"
                        v-can="'provider.update'"
                        ><i class="ri-ball-pen-fill ml-1"></i
                      ></b-button>
                      <b-button
                        v-can="'provider.delete'"
                        v-b-tooltip.hover
                        title="Eliminar"
                        variant=" iq-bg-danger mr-1 mb-1 col-5"
                        size="sm"
                        @click="showMsgBoxTwo(data.item)"
                        ><i class="ri-delete-bin-line ml-1"></i
                      ></b-button>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  class="mt-3"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  pills
                  size="sm"
                  align="center"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
  <script>
import { core } from "@/config/pluginInit";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ListProvedores",
  async mounted() {
     this.$store.commit("SET_BREADCRUMB", this.items);
    core.index();
    this.isBusy = true;
    await this.listProviders();
    this.totalRows = this.getProviders.length;
    this.isBusy = false;
  },
  methods: {
    ...mapActions({
      listProviders: "providers/fechProviders",
      destroyProviders: 'providers/destroyProviders'
    }),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    showMsgBoxTwo(item) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("¿Desea eliminar este registro?.", {
          title: "Confirmación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          value ? this.remove(item) : "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async remove(item) {
      try {
        const res = await this.destroyProviders(item.id);
        this.totalRows = this.getProviders.length;
        core.showSnackbar("success", res);
      } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      getProviders: "providers/getProviders",
    }),
  },
  data() {
    return {
      filter: "",
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      isBusy: false,
      columns: [
        { label: "Nombre", key: "fantasy_name", class: "text-center" },
        { label: "Razón Social", key: "business_name", class: "text-center" },
        { label: "RUT", key: "rut", class: "text-center" },
        { label: "Giro", key: "gire", class: "text-center" },
        { label: "Comuna", key: "comuna", class: "text-center" },
        { label: "Dirección", key: "address", class: "text-center" },
        { label: "Acciones", key: "action", class: "text-center" },
      ],
      rows: [],
      items: [
        {
          html: 'Inicio',
          to: "/",
        },
        {
          text: "Listado de proveedores",
          active: true,
        },
      ],
    };
  },
};
</script>
<style>
</style>
